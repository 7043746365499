import { cloudApplicationUrl, cloudService, projectCode } from "./api/api-config";

export const description = [
  "Realtime AI examiner interview",
  "Unlimited tests on new topics",
  "Word-to-word performance feedback",
  "Track your progress through analytics",
];

export function formatDateToCustomFormat(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  return `${day} ${month}`;
}

export const setLinearProgressColors = (item: any) => {
  let average;
  let percentage;
  let name;
  let outerbgcolor;
  let innerbgcolor;

  if (item) {
    average = item.score;
    percentage = (average / 9) * 100;
    name = item.parameter;
  }

  if (percentage) {
    if (percentage >= 0 && percentage < 50) {
      outerbgcolor = "#FFE4DA";
      innerbgcolor = "#FF495C";
    } else if (percentage >= 50 && percentage < 77) {
      outerbgcolor = "#FFECA9";
      innerbgcolor = "#FFBB28";
    } else if (percentage >= 77 && percentage <= 100) {
      outerbgcolor = "#DCF8A7";
      innerbgcolor = "#59A51A";
    }
  } else {
    outerbgcolor = "#FFE4DA";
    innerbgcolor = "#FF495C";
  }

  return { average, percentage, name, outerbgcolor, innerbgcolor };
};

export const calculatePercentage = (score: number, total = 9) => {
  return (score / total) * 100;
};

export const getColorsByPercentage = (percentage: any) => {
  let pathColor = "#3FC7FA";
  let trailColor = "#d6d6d6";
  let textColor = "black";

  if (percentage >= 0 && percentage <= 50) {
    pathColor = "#FF495C";
    trailColor = "#FFC2B6";
    textColor = "#FF495C";
  } else if (percentage > 50 && percentage <= 77) {
    pathColor = "#FFBB28";
    trailColor = "#FFECA9";
    textColor = "#FFBB28";
  } else if (percentage > 77 && percentage <= 100) {
    pathColor = "#59A51A";
    trailColor = "#DCF8A7";
    textColor = "#59A51A";
  }

  return { pathColor, trailColor, textColor };
};

export const getFraction = (partDetails: any) => {
  if (partDetails) {
    return partDetails.overall_score;
  } else {
    return "0";
  }
};

export const generateQuestionsArray = (range: string): number[] => {
  const myArray: number[] = [];

  if (range.includes("-")) {
    const [start, end] = range.split("-");
    const startNumber = parseInt(start, 10);
    const endNumber = parseInt(end, 10);

    for (let i = startNumber; i <= endNumber; i++) {
      myArray.push(i);
    }
  } else {
    myArray.push(parseInt(range, 10));
  }

  return myArray;
};

export const transformString = (input: any) => {
  // Convert to lowercase
  let result = input?.toLowerCase();

  // Remove spaces
  result = result?.replace(/\s/g, "");

  // Remove commas
  result = result?.replace(/,/g, "");

  // Remove periods (dots)
  result = result?.replace(/\./g, "");

  return result;
};

export  const caluculateScore = (allUsewrAnswers: any, answerSheet: any) => {
  let totalScore = 0;
  allUsewrAnswers.forEach((item: any) => {
    let userQid = item.qId;
    let userAnswer = item.answer;
    let questionInBeArray = answerSheet.findIndex(
      (item: any) => item.qId == userQid
    );

    if (questionInBeArray !== -1) {
      let matchingQuestion = answerSheet[questionInBeArray];

      if (Array.isArray(matchingQuestion.correctAnswer)) {
        if (transformString(matchingQuestion.questionType) === "shortanswer") {
          let userChoosenAnswer = transformString(userAnswer);
          let transformedCorrectAnswersArray =
            matchingQuestion.correctAnswer.map((item: any) =>
              transformString(item)
            );
          if (transformedCorrectAnswersArray.includes(userChoosenAnswer)) {
            totalScore = totalScore + 1;
          }
        } else if (
          transformString(matchingQuestion.questionType) === "multiplechoice(morethanoneanswer)"
        ) {
          if (userAnswer.length > 0 && matchingQuestion.correctAnswer.length > 0) {
            let sortedArr1 = userAnswer.slice().sort();
            let sortedArr2 = matchingQuestion.correctAnswer.slice().sort();
            sortedArr1 = sortedArr1.map((item: any) => transformString(item));
          sortedArr2 = sortedArr2.map((item: any) => transformString(item));

            for (let i = 0; i < sortedArr2.length; i++) {
              if (sortedArr1[i] && !sortedArr2.includes(sortedArr1[i])) {
                break;
              }
              if (sortedArr1.length > 1 && i === sortedArr2.length - 1) {
                totalScore = totalScore + 2;
              }
            }
          }
        }
      } else {
        let exactAnswer = transformString(matchingQuestion.correctAnswer);
        let userChoosenAnswer = transformString(userAnswer);
        if (exactAnswer === userChoosenAnswer) {
          totalScore++;
        }
      }
    }
  });

  return totalScore;
};

export const aiFeedbackPayload = (allUsewrAnswers: any, answerSheet: any, module: string) => {
  let questions: any = [];
  answerSheet?.forEach((item: any) => {
    let userAnswer = allUsewrAnswers.find((ans: any) => ans.qId == item.qId);
    let isCorrectAnswer;
    if (module === "reading" && item.tbody) {
      item.tbody.forEach((obj: any) => {
        Object.keys(obj).forEach((itm, ind) => {
          if (itm.includes("qId")) {
            delete obj[itm];
          }
        });
      });
    }
    if (
      userAnswer &&
      Array.isArray(userAnswer?.answer) && transformString(item.questionType) === "multiplechoice(morethanoneanswer)" &&
      userAnswer?.answer.length > 0
    ) {
      let userAnswerArr = userAnswer.answer.slice().sort();
      let correctAnswerArr = item.correctAnswer.slice().sort();
      let transformedUserAnswer = userAnswerArr.map((item: any) =>
        transformString(item)
      );
      let transformedCorrectAnswer = correctAnswerArr.map((item: any) =>
        transformString(item)
      );
      for (let i = 0; i < transformedCorrectAnswer.length; i++) {
        if (transformedUserAnswer[i] && !transformedCorrectAnswer.includes(transformedUserAnswer[i])) {
          isCorrectAnswer = false;
          break;
        }
        if (transformedUserAnswer.length > 1 && i === transformedCorrectAnswer.length - 1 )  {
          isCorrectAnswer = true;
        }
      }
    } else if (transformString(item.questionType) === "shortanswer") {
      let userChoosenAnswer = userAnswer?.answer
        ? transformString(userAnswer.answer)
        : "";
      let transformedCorrectAnswersArray = item.correctAnswer.map(
        (item: any) => transformString(item)
      );
      if (transformedCorrectAnswersArray.includes(userChoosenAnswer)) {
        isCorrectAnswer = true;
      } else {
        isCorrectAnswer = false;
      }
    } else {
      let transformedUserAnswer = userAnswer?.answer
        ? transformString(userAnswer.answer)
        : "";
      let transformedExactAnswer =
        typeof item?.correctAnswer === "string"
          ? transformString(item.correctAnswer)
          : item.correctAnswer;
      isCorrectAnswer = userAnswer
        ? transformedExactAnswer === transformedUserAnswer
        : false;
    }
    let question = {
      qId: item.qId,
      questionType: item.questionType,
      qName: item.qName ? item.qName : item.questionDescription,
      exactAnswer: item.correctAnswer || item.answer,
      userAnswer: userAnswer ? userAnswer.answer : "",
      isCorrect: isCorrectAnswer,
      isAttempted: !!userAnswer,
      table: module === "reading" ? item?.tbody :item?.table,
    };
    questions.push(question);
  });
  return questions;
};

export const getAverageTestScore = async(store : any) => {
  try {
    store.isGrapghDataLoading = true;
    const res = await store.getGraphData();
    if (res.status === 200) {
      store.averageScore = res.data.avg_score ? res.data.avg_score : 0;
      const transformedData = res.data.data.reduce(
        (result: any, item: any) => {
          item.score = item.score.toFixed(2);
          let decimalPart = Number(
            (item.score - Math.floor(item.score)).toFixed(2)
          );
          let value;
          if (decimalPart > 0 && decimalPart <= 0.24) {
            value = Math.floor(item.score);
          } else if (decimalPart >= 0.25 && decimalPart <= 0.49) {
            value = Math.floor(item.score) + 0.5;
          } else if (decimalPart > 0.5 && decimalPart <= 0.74) {
            value = Math.floor(item.score) + 0.5;
          } else if (decimalPart >= 0.75 && decimalPart <= 0.99) {
            value = Math.ceil(item.score);
          } else {
            value = item.score;
          }
          result.scores.push(Number(value));
          const dateObject = new Date(item.date);
          result.dates.push(formatDateToCustomFormat(dateObject));
          return result;
        },
        { scores: [] as string[], dates: [] as number[] }
      );
      store.graphData = transformedData;
      store.isGrapghDataLoading = false;
      store.hasUserTakenAnyTest = res.data.data.length > 0;
      return res.data.avg_score;
    }
  } catch (e) {
    store.isGrapghDataLoading = false;
    console.error(e, "ERROR");
  }
 
}
export const questionIdClassName = (item:any,aiFeedbackquestionId:any) => {
  if(item.isCorrect){
    return `font-bold border border-[#59A51A] ${aiFeedbackquestionId !== item.qId ? " text-[#59A51A]":"bg-[#59A51A] text-[#FFFF] "}`
  }else if(item.isAttempted){
      return  `font-bold border border-[#DB3555] ${aiFeedbackquestionId !== item.qId ? "text-[#DB3555]":"bg-[#DB3555] text-[#FFFF]"} `
  }else{
    return `font-bold border border-[#737E93] ${aiFeedbackquestionId !== item.qId ? "text-[#737E93]":"bg-[#737E93] text-[#FFFF] "}`
  }
}
export const getUserAnswerTextClassName = (aiFeedback: any) => {
  if (aiFeedback.isCorrect) {
    return "text-[#59A51A]";
  } else if (!aiFeedback.isAttempted) {
    return "text-[#4E5664]";
  } else {
    return "text-[#DB3555]";
  }
};

export const getExactCorrectClassName = (aiFeedback:any) =>{
  if( aiFeedback.isCorrect){
    return `text-[#59A51A] border border-[#59A51A]`
  }else if(aiFeedback.isAttempted){
   return `text-[#DB3555] border border-[#DB3555]`
  }else{
    return `text-[#4E5664] border border-[#4E5664]`
  }
  }
  
  const renderAnswerList = (answers: string[],questionId:any) => {
    return (
      <div>
        <ul>
          {answers.length > 0
            ? answers.map((item: string) => (
                  <li key={item}>{item}</li>
              ))
            : "Not Attempted"}
        </ul>
      </div>
    );
  };
  
  const renderAnswer = (answer: any,questionId:any) => {
    if (Array.isArray(answer)) {
      return renderAnswerList(answer,questionId);
    } else if (answer) {
      return answer;
    } else {
      return "Not Attempted";
    }
  };
  
  export const getAnswerRender = (answer: any, type: string) => {
    if (type === "userAnswer") {
      return renderAnswer(answer.userAnswer,answer.questionId);
    } else if (type === "exactAnswer") {
      return renderAnswer(answer.exactAnswer,answer.questionId);
    }
    return null;
  };
  
  export const getTheAnswerStatement = (aiFeedback:any) => {
    if(aiFeedback.isCorrect){
      return "You gave correct answer for this question"
    }else if(aiFeedback.isAttempted){
      return "You gave incorrect answer for this question"
    }else{
      return "You haven't made an attempt to answer the question"
    }
  }

  export const checkApplicationStatus = async () => {
    const url = `${cloudService}/api/servicestatus/fetchall`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
      'applicationurl': cloudApplicationUrl,
      },
    });

    if (response.ok) {
      const data = await response.json();
      if(data?.status === "success"){
        const dataArr = data.data.filter((item: any) => item.isEnabled && item.projects.includes(projectCode))
        const messages = dataArr.map((item: any) => item.warningMessage);
        return messages ;
      }
    }

    return null;
  };
  