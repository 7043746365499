import React, { ReactNode, useEffect } from "react";
import { observer } from "mobx-react-lite";
import MenuIcon from "../icons/Menu.svg";
import { useStore } from "../Hooks/use-store";
import Profile from "./useAvatar";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import { useLocation } from 'react-router-dom';
import tinyImage from '../icons/pngwing.com.png'
import ProgressiveImage from "../components/common/ProgressiveImage";
import { checkApplicationStatus } from "../utils";

interface Authenticated {
  children?: ReactNode; // Specify children as a ReactNode
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Authenticated: React.FC<Authenticated> = observer(({ children }) => {
  const { dashboardStore,userStore,startTestStore } = useStore();
  const {dashboardNavigation} =dashboardStore;
  const {userDashboards} = userStore;
  const location = useLocation();

 
  let widthValue;
if (dashboardStore.isMobile) {
  widthValue = "100vw";
} else if (dashboardStore.showDashboardSideBar) {
  widthValue = "calc(100vw - 240px)";
} else {
  widthValue = "calc(100vw - 72px)";
}

  
  useEffect(() => {
    const handleResize = () => {
      dashboardStore.setIsMobile(window.innerWidth <= 768)
    };

    handleResize(); // Set initial size
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const main = <main className="mt-28">{children}</main>;

  const renderProfilePic = () => {
if(userStore.profilePic){
  return (
  <ProgressiveImage src={userStore.profilePic} placeHolderImage={tinyImage} height='60' width='45' isRounded={true} alt="profile pic"/>
)   
   
}else{
return <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-[#FFEAEA] flex justify-center items-center text-[#4E5664] text-xl my-[1rem]"></div>
}
  }

   
  const topbar = (
    <nav
      id="navbar"
      className={`${
        !dashboardStore.showProfile
          ? "z-[2] h-20 fixed top-0 flex bg-white px-4 items-center flex-row justify-between border-b border-gray-300 py-4"
          : "z-[2] h-20 fixed top-0 flex bg-white px-4  border-b border-gray-300 py-4"
      }`}
      style={{
        width:widthValue
      }}
    >
      <div className="flex flex-row items-center">
        {!dashboardStore.showProfile && (
          <div
            className={`flex flex-col ${
              dashboardStore.showDashboardSideBar ? "ml-4" : "ml-6"
            }`}
          >
            {dashboardNavigation.map((item) => {
              if (item.current) {
                return (
                  <div
                    className={`flex flex-row font-bold font-Avenir  text-xl text-[#292D35]`}
                    key={item.name}
                  >
                    <ProgressiveImage isRounded={true} src={item.activeIcon?item.activeIcon:item.icon} placeHolderImage={tinyImage}  height='50' width='45' alt={item.name}/>
                    {/* <img
                      src={ item.activeIcon?item.activeIcon:item.icon}
                      alt={item.name}
                      className="w-[45px] h-[50px]"
                    /> */}
                    <span className={`self-center pl-4`}> {item.name}</span>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>

      <div className="flex items-center mr-5 px-12">
        <div
          className="group cursor-pointer relative">
        {dashboardStore.isMobile ?  renderProfilePic(): <Profile />}  
        </div>
        <button
          className="text-20 ml-2 font-medium font-Avenir text-[#3C424C] cursor-pointer"
          onClick={() => {
            dashboardStore.showProfilePage(true);
            dashboardStore.setProfilePageTabIndex(0);
            if (dashboardStore.isMobile) {
              navigate("/profile");
            } else {
              navigate("/profile");
            }
           
          }}
        >
          Profile
        </button>
      </div>
    </nav>
  );

  const sidebar = (
    <div id="containerSidebar" className="z-[999]">
      <div className="navbar-menu relative z-[999]">
        <nav
          id="sidebar"
          className={classNames(
            "fixed top-0 h-full flex   flex-col  overflow-x-hidden overflow-y-auto bg-white pb-2   border-r border-gray-200  pt-6 sm:max-w-xs " , dashboardStore.showDashboardSideBar ? "show w-3/4 sm:max-w-[240px] lg:w-[240px] " : "show w-[72px]"
          )}
        >
          <div className="pl-7">
            <div className="flex flex-row items-center">
              <button
                type="button"
                className="text-xl text-white mr-4"
                onClick={() => {
                  dashboardStore.setShowDashboardSideBar(!dashboardStore.showDashboardSideBar);
                }}
              >
                <div className="h-10 w-10 flex items-center justify-center rounded-full mt-[20px] ml-[24px]">
                  {/* <img src={MenuIcon} /> */}
                  <ProgressiveImage isRounded={true} src={MenuIcon} placeHolderImage={tinyImage}  height='36' width='36' alt='teacher'/>
                  <h2 className={`text-[#E31837] font-manrope font-semibold text-24 ${dashboardStore.showDashboardSideBar? "pl-[14px]" : "pl-[24px]"}`}>
                    IELTS
                  </h2>
                </div>
              </button>
            </div>
          </div>

          <div className="mt-10">
            <ul>
              {dashboardNavigation.map((item) => {
                if(userDashboards.includes(item.name.toUpperCase())){
                return(
                <Tooltip title={item.name} placement="right" disableHoverListener={dashboardStore.showDashboardSideBar} key={item.name}>
                <li key={item.name}>
                  
                  <button
                    className={classNames(
                      !dashboardStore.showProfile  && item.current
                        ? "bg-[#FFEAEA] text-[#E31837] fill-[#E31837]"
                        : "text-[#737E93] hover:text-[#E31837] hover:bg-[#FFEAEA]",
                      "pl-7 py-3 group items-center flex gap-x-3 rounded-md text-[16px] leading-6 cursor-pointer mb-3 w-full p-0 border-0"
                    )}
                    onClick={() => {
                      dashboardStore.updateCurrentNavBar(item.name);
                      startTestStore.choosePlansDialog(false);
                      navigate(item.href);
                      dashboardStore.showProfilePage(false);
                      startTestStore.setStep(0);
                    }}
                  >
                     <ProgressiveImage isRounded={true} src={!dashboardStore.showProfile  && item.current ? item.activeIcon : item.icon} placeHolderImage={tinyImage}   alt={item.name}/>
                    {dashboardStore.showDashboardSideBar && <p className="text-xl font-medium	">{item.name}</p>}
                  </button>
                </li>
                </Tooltip>
              )}})}
            </ul>
          </div>
        </nav>
      </div>
      {/* <div className="mx-auto lg:ml-80"></div> */}
    </div>
  );
  const mobileNav = (<div id="containerSidebar" className="z-[999]">
  <div className="navbar-menu relative z-[999]">
    <nav
      id="sidebar"
      className={classNames(
        "fixed bottom-0 w-full flex   flex-row  overflow-x-hidden overflow-y-auto bg-white pb-2   border-r border-gray-200  "
      )}
    >
      <div className="w-full">
        <ul  className="flex flex-row justify-around">
          {dashboardNavigation.map((item) => {
            if(userDashboards.includes(item.name.toUpperCase())){
            return(
            <li key={item.name} >
              <button
                className={classNames(
                  !dashboardStore.showProfile  && item.current
                    ? "bg-[#FFEAEA] text-[#E31837] fill-[#E31837]"
                    : "text-[#737E93] hover:text-[#E31837] hover:bg-[#FFEAEA]",
                  "items-center flex rounded-md text-[16px]  cursor-pointer flex-col w-full p-0 border-0"
                )}
                onClick={() => {
                  dashboardStore.updateCurrentNavBar(item.name);
                  dashboardStore.showProfilePage(false);
                  navigate(item.href);
                }}
              >
                 <ProgressiveImage isRounded={true} src={!dashboardStore.showProfile  && item.current ? item.activeIcon : item.icon} placeHolderImage={tinyImage}  height='16' width='22' alt={item.name}/>
                {/* <img
                  src={!dashboardStore.showProfile  && item.current ? item.activeIcon : item.icon}
                  alt={item.name}
                  className={
                    item.current
                      ? "fill-current  cursor-pointer text-red"
                      : " cursor-pointer"
                  }
                /> */}
                {dashboardStore.showDashboardSideBar &&  <p className="text-sm font-medium">{item.name === "Dashboard" ? "Home " : item.name}</p>}
              </button>
              
            </li>
          )}})}
        </ul>
      </div>
    </nav>
  </div>
  {/* <div className="mx-auto lg:ml-80"></div> */}
</div>)
  
  useEffect(() => {
    const currentRoute = location.pathname;
  
    let filteredNavItem = dashboardNavigation.find((item) => item.href === currentRoute);
    
    filteredNavItem && dashboardStore.updateCurrentNavBar(filteredNavItem.name)
  },[navigate])

  useEffect( () => {
    const fetchApplicationStatus = async () => {
      const messages = await checkApplicationStatus();
      if(messages?.length > 0){
        dashboardStore.setErrorMessages(messages);
        navigate('/error');
      }
    };

    fetchApplicationStatus();
    const intervalId = setInterval(fetchApplicationStatus, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);
 
  return (
    <div className="flex flex-row bg-[#f8f9fd] overflow-hidden  min-h-screen">
        {dashboardStore.isMobile ?  mobileNav: sidebar}
        <div className={`${!dashboardStore.showDashboardSideBar ? 'lg:ml-[72px]':"lg:ml-[240px]"} w-full relative`} >
          {topbar}
          {main}
        </div>
      </div>
  );
});

export default Authenticated;
