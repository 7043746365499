import React,{useState,useEffect, useRef} from 'react'
import { create } from 'apisauce';
import Loader from "../../src/Loader/index";
import alert from '../icons/alert.svg'
import greenCircle from "../icons/greenCircle.svg"
import edit from "../icons/Edit.svg"
import { Dialog,DialogActions,DialogContent,DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';


const host = window.location.host;
const eiURL = host === 'ielts.web.cambri.ai' || host === 'web-ielts-prod.web.app' ? 'https://api.ei.fallnspring.com' : 'https://api.ei.prismatica.ai';

const SslCertificates = () => {

    const [data, setData] = useState<any[]>();
    const [loading,setLoading] = useState<boolean>();
    const [error,setError] = useState<boolean>();
    const [dialogOpen,setDialogOpen] = useState(false);
    const [certData,setCertData] = useState({
      get_id: "",
      isActive: true,
      projectName: "",
      certIssueDate: "",
      certExpiredDate: "",
      domainName: "",
      status: "SUCCESS",
      exception: "",
      lastExcecutedDate: "",
      createdDate: "",
      updatedDate: "",
      validityPeriodInDays: '',
      hostType: "FRONTEND",
      environment: "DEV",
      emails: [],
      eventType: "SSLALERT"
    })

    const dialogType : any = useRef();
  
    useEffect(() => {
     getCertificatesData();
    },[])

    const handleClose = () => {
      setDialogOpen(false);
    }

    const handleAdd = () => {
        setCertData({
          get_id: "",
          isActive: true,
          projectName: "",
          certIssueDate: "",
          certExpiredDate: "",
          domainName: "",
          status: "SUCCESS",
          exception: "",
          lastExcecutedDate: "",
          createdDate: "",
          updatedDate: "",
          validityPeriodInDays: '',
          hostType: "FRONTEND",
          environment: "DEV",
          emails: [],
          eventType: "SSLALERT"
        });
        dialogType.current = "Add"
        setDialogOpen(true);
    }

    const handleSave = async () => {
      if(dialogType.current ==='Add'){
        setLoading(true);
        let payLoad = {...certData};
        try{
          let response = await create({
            baseURL: eiURL,
            headers: {
              'Accept': "application/json",
              'Content-Type': 'application/json'
            },
           }).post(`/api/sslcertificate/create`,payLoad);
          if(response.status === 200){
            setLoading(false);
            toast.success("Certificate added successfully");
            getCertificatesData();
          }else{
            setLoading(false);
            toast.error("Failed to add certificate");
            getCertificatesData();
          }
        }catch(err){
          setLoading(false);
          toast.error("Failed to add certificate");
          getCertificatesData();
        }
      }else if(dialogType.current ==='Edit'){
        let domain = certData.domainName;
        const expiryDate  = certData.certExpiredDate.split('T')[0];
        try{
          let response = await create({
            baseURL: eiURL,
            headers: {
              'Accept': "application/json",
              'Content-Type': 'application/json'
            },
          }).put(`/api/sslcertificate/update?domainName=${domain}&certExpiredDate=${expiryDate}`);
          if(response.status === 200){                                                             //need to update the status code in put call
            setLoading(false);
            toast.success("Certificate Updated successfully");
            getCertificatesData();
          }else{
            setLoading(false);
            toast.error("Failed to update the certificate");
            getCertificatesData();
          }
        } catch(err){
          setLoading(false);
          toast.error("Failed to update the certificate");
          getCertificatesData();
        }
      }
      handleClose();
    }

    const handleChange = (e : any) => {
     let newData : any = {...certData};
     if(e.target.name === 'certIssueDate' || e.target.name === 'certExpiredDate'){
       newData[e.target.name] = new Date(e.target.value).toISOString();
     }else if(e.target.name === 'validityPeriodInDays'){
        if(Number(e.target.value) < 0){
          newData[e.target.name] = 0;
        }else{
          newData[e.target.name] =  Number(e.target.value);
        }
     }else if(e.target.name === 'emails'){
        const emailArray = e.target.value.split(',').map((email : any) => email.trim());
        newData[e.target.name] = emailArray;
     }else {
        newData[e.target.name] = e.target.value;
     }
     setCertData(newData);
    }
  
    const getCertificatesData = async () => {
      setLoading(true);
      try{
          let response = await create({
              baseURL: eiURL,
              headers: {
                'Accept': "application/json",
                'Content-Type': 'application/json'
              },
             }).get(`/api/sslcertificate/getall`);
         if (response?.status === 200){
          setData(response.data as any);
          setLoading(false);
         }else{
          setLoading(false);
          setError(true);
         }
      }catch (err) {
        setLoading(false);
        setError(true);
        console.error(err);
      }
  }

  const handleEdit = (certInfo : any) => {
    setCertData(certInfo);
    dialogType.current = 'Edit'
    setDialogOpen(true)
  }

  const handleDisabled = () => {
    if(dialogType.current ==='Add'){
      if(certData.projectName !== "" && certData.certIssueDate !== "" && certData.certExpiredDate !== "" && certData.domainName !== "" && certData.validityPeriodInDays !== '' && certData.hostType !== "" && certData.environment !== ""){
        return  false;
      }else {
        return true;
      }
    }else if(dialogType.current ==='Edit'){
       if(certData.certExpiredDate !== "" && certData.domainName !== ""){
        return false;
       }else {
        return true;
       }
    }
  }

  const customDialog = () => {
     if(dialogType.current ==='Add'){
      return (
          <>
            <span className='flex justify-between'>
                <label htmlFor="projectName">Project Name:</label>
                <input type="text" id="projectName" name="projectName" value={certData.projectName} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="issueDate">Issue Date:</label>
                <input type="date" id="issueDate" name="certIssueDate" value={certData.certIssueDate.split('T')[0]} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="expiryDate">Expiry Date:</label>
                <input type="date" id="expiryDate" name="certExpiredDate" min={new Date().toISOString().split('T')[0]}  value={certData.certExpiredDate.split('T')[0]} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="domainName">Domain Name:</label> 
                <input type="text" id="domainName" name="domainName" value={certData.domainName} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="validity">Validity(in days):&nbsp;</label>
                <input type="number" id="validity" name="validityPeriodInDays" value={certData.validityPeriodInDays} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="hostType">Host Type:</label>
                <select id="hostType" name="hostType" className='border rounded w-40 h-7 px-1' value={certData.hostType} onChange={handleChange} >
                    <option value="FRONTEND">Frontend</option>
                    <option value="BACKEND">Backend</option>
                    <option value="DATABASE">Database</option>
                    <option value="OTHERS">Others</option>
                </select>
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="environment">Environment:</label>
                <select id="environment" name="environment" value={certData.environment} onChange={handleChange} className='border rounded w-40 h-7 px-1' >
                    <option value="DEV">Development</option>
                    <option value="TEST">Testing</option>
                    <option value="STAGING">Staging</option>
                    <option value="PRODUCTION">Production</option>
                    <option value="OTHERS">Others</option>
                </select>
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="emails">Emails:</label>
                <input type="email" id="emails" name="emails" value={certData.emails.length > 0 ? certData.emails.join(', ') : ''} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
          </>
      )
     }else if(dialogType.current === 'Edit'){
       return (
        <>
            <span className='flex justify-between'>
                <label htmlFor="expiryDate">Expiry Date:</label>
                <input type="date" id="expiryDate" name="certExpiredDate" min={new Date().toISOString().split('T')[0]} value={certData.certExpiredDate.split('T')[0]} onChange={handleChange} className='border rounded w-40 h-7 p-1' />
            </span>
            
            <span className='flex justify-between'>
                <label htmlFor="domainName">Domain Name:</label> 
                <input type="text" id="domainName" name="domainName" value={certData.domainName} onChange={handleChange} className='border rounded w-40 h-7 p-1'/>
            </span>
        </>
       )
     }
  }

  let stagingData = data?.filter((item) => item.environment === "STAGING");
  let productionData = data?.filter((item)=> item.environment==="PRODUCTION");

  const renderContent = () => {
    if (loading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    }else if (error) {
      return (
        <p className="text-center text-2xl font-bold mb-4">Something went wrong</p>
      );
    }else {
      return (
        <>
          <div className='mx-auto w-[90%] flex justify-end'>
            <button className="text-[#FFFFFF] w-fit bg-[#E31837] px-2 h-10 border-[1px] rounded mb-4" onClick={handleAdd}>
              Add Certificate
            </button>
          </div>
  
          <div >
            <h1 className='mx-auto w-[90%] text-2xl font-semibold mb-5 text-center'>Production</h1>
            <TableComponent tableData={productionData} handleEdit={handleEdit}/>
          </div>
  
          <div className='my-5'>
            <h1 className='mx-auto w-[90%] text-2xl font-semibold mb-5 text-center'>Staging</h1>
            <TableComponent tableData={stagingData} handleEdit={handleEdit}/>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {renderContent()}
      
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Add Project</DialogTitle>
        <DialogContent>
          <form className='flex flex-col space-y-5'>
            {customDialog()}
          </form>
        </DialogContent>
        <DialogActions className='space-x-5 mb-5 mr-5'>
          <button className='text-[#FFFFFF] w-fit bg-[#E31837] px-2 h-7 border-[1px] rounded' onClick={handleClose}>Cancel</button>
          <button className={`text-[#FFFFFF] w-fit px-2 h-7 border-[1px] rounded ${ handleDisabled() ? 'text-[#E31837] bg-[#7d7979]' : 'bg-[#E31837] text-[#FFFFFF]'}`} disabled={handleDisabled()} onClick={handleSave}>Save</button>
        </DialogActions>
      </Dialog>
    </>
    
   )
}

export default SslCertificates;

const TableComponent = (props: any) => {

  const expiryDaysLeft = (date: any) => {
    let currentDate = new Date();
    let diffDays = Math.ceil(
      (new Date(date).getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
    );
    return diffDays;
  };

  return (
    <table className="table-auto border-collapse mx-auto w-[90%]">
      <thead className="sticky -top-1 bg-white">
        <tr>
          <th className="py-2 px-4 border">Project Name</th>
          <th className="py-2 px-4 border">Host Type</th>
          <th className="py-2 px-4 border">Domain name</th>
          <th className="py-2 px-4 border">Expiry Date</th>
          <th className="py-2 px-4 border">Current Status</th>
          <th className="py-2 px-4 border">Expiry Date Countdown</th>
          <th className="py-2 px-4 border">Latest Execution Status</th>
          <th className="py-2 px-4 border">Exception</th>
          {/* <th className="py-2 px-4 border">Email</th> */}
        </tr>
      </thead>
      <tbody className="max-h-[400px] overflow-y-auto">
        {props?.tableData?.map((item: any) => (
            <tr key={item.projectName}>
              <td className="py-2 px-4 border text-center">
                {item.projectName}
              </td>
              <td className="py-2 px-4 border text-center">{item.hostType}</td>
              <td className="py-2 px-4 border max-w-[15rem] break-words">
                {item.domainName}
              </td>
              <td className="py-2 px-4 border text-center">
                {new Date(item.certExpiredDate).toLocaleDateString("en-IN", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
                <button onClick={() => props.handleEdit(item)}>
                <img
                  src={edit}
                  alt="status"
                  className="h-5 w-5 mx-auto my-auto cursor-pointer"
                />
                </button>
              </td>
              <td className="py-2 px-4 border text-center">
                <img
                  src={
                    expiryDaysLeft(item.certExpiredDate) < 5
                      ? alert
                      : greenCircle
                  }
                  alt="status"
                  className="h-10 w-10 mx-auto"
                />
              </td>
              <td className="py-2 px-4 border text-center">
                {expiryDaysLeft(item.certExpiredDate)}
              </td>
              <td className="py-2 px-4 border text-center">{item.status}</td>
              <td className="py-2 px-4 border text-center max-w-[15rem] break-words">
                {item.exception || "N/A"}
              </td>
              {/* <td className="py-2 px-4 border max-w-[15rem] break-words">{item.emails.join(',\n')}</td> */}
            </tr>
          ))}
      </tbody>
    </table>
  );
};