import apiSauce from "./api-sauce";

export class StartTestWritingApi {

    async startFreeTestWriting(id: any): Promise<any> {

        return await apiSauce.apisauce?.post(`/start-writing-test?test_id=${id}`)
    }
    async fetchFreeTestDataWriting():Promise<any>{
        return await apiSauce.apisauce?.get('/free-writing-tests')

    }
    async generateProTestApiWriting(typeTst: any): Promise<any> {

        return await apiSauce.apisauce?.get(`/generate/writing/testv3?type=${typeTst}`)
    }

    async getFreeTestPartOneDataApiWriting(payload: any): Promise<any> {
        return await apiSauce.apisauce?.get(`/get-writing_test-v2?test_id=${payload.id}&type=${payload.type}`)
    }

    async submitAnswerApiWriting(payload: any): Promise<any> {
        return await apiSauce.apisauce?.post(`/submit-writing-answer`, payload);

    }

    async endTestApiWriting(testId: number): Promise<any> {
        return await apiSauce.apisauce?.get(`/end-writing-test?test_id=${testId}`)
    }
    

}