import { useRef, useState } from "react";
import Icon from "../../../icons/examinerPro.svg";
import PlayIcon from "../../../icons/PlayIcon.svg";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../Hooks/use-store";
import LockedIcon from "../../../icons/Lock.svg";
import { useNavigate } from "react-router-dom";
import AIFeedbackPartHeader from '../../common/aiFeedbackPartHeader';


const PartBody = (props: any) => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const audioRef = useRef<HTMLAudioElement>(null);

  const playAudio =  (audioUrl: string, audioElement: HTMLAudioElement) => {
    // Stop the currently playing audio, if any
    if (currentAudio) {
     currentAudio.pause();
    }

    // Set the source and play the audio
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setCurrentAudio(audioRef.current);
    }
  };

  return (
    <div className="relative ">
      <div className="flex flex-row justify-start">
        <img src={Icon} alt="examinerImg" />
        <div className="border border-[#737E93] rounded-[16px] w-3/5 p-[8px] text-[#737E93] mx-[8px]">
          {" "}
          {props.item.question.text}
        </div>
        <button
          className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[20px]"
          onClick={() =>
            audioRef.current &&
            playAudio(props.item.question.audio_file, audioRef.current)
          }
        >
          <img src={PlayIcon} alt="playImg" />
          <audio ref={audioRef}>
            <track
              kind="captions"
              src=""
              srcLang="en"
              label="No captions available"
            />
          </audio>
        </button>
      </div>
      <div className="flex justify-end pt-[16px] pb-[8px] pr-[16px]">
        <button
          className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[16px]"
          onClick={() =>
            audioRef.current &&
            playAudio(props.item.answer.answer_audio, audioRef.current)
          }
        >
          <img src={PlayIcon} alt="playImg" />
          <audio ref={audioRef}>
            <track
              kind="captions"
              src=""
              srcLang="en"
              label="No captions available"
            />
          </audio>
        </button>
        <div className="border border-[#737E93] rounded-[16px] w-3/5 p-[8px] text-[#737E93] ml-[8px]">
          {props.item.answer.answer}
        </div>
      </div>
      {props.item.answer.pronunciation_score == 0 &&
        props.item.answer.fluency_score == 0 &&
        props.item.answer.grammar_score == 0 &&
        props.item.answer.vocabulary_score == 0 && (
          <div className="text-red-600 text-xs font-medium flex justify-end pr-4">
            This Answer is Irrelevant
          </div>
        )}
      <div className="flex justify-end pr-4 pb-[16px] text-[#737E93] text-[12px] ">
        Pronunciation:
        {Math.round((props.item.answer.pronunciation_score / 9) * 100)}%
        Fluency:{Math.round((props.item.answer.fluency_score / 9) * 100)}%
        Grammar: {Math.round((props.item.answer.grammar_score / 9) * 100)}%
        Vocabulary:
        {Math.round((props.item.answer.vocabulary_score / 9) * 100)}%
      </div>
    </div>
  );
};

const AiFeedback = () => {
  const { resultsStore, dashboardStore, userStore } =
    useStore();
  const [accordian, setAccordian] = useState({
    part1: false,
    part2: false,
    part3: false,
  });
  const navigate = useNavigate();

  let {
    getQuestionAnalysisPartOne,
    getQuestionAnalysisPartTwo,
    getQuestionAnalysisPartThree,
  } = resultsStore;
  let { completedTestType } = resultsStore;
  let partOneAvgFraction = `${getQuestionAnalysisPartOne.score}/${getQuestionAnalysisPartOne.max}`;
  let partTwoAvgFraction = `${getQuestionAnalysisPartTwo.score}/${getQuestionAnalysisPartTwo.max}`;
  let partThreeAvgFraction = `${getQuestionAnalysisPartThree.score}/${getQuestionAnalysisPartThree.max}`;
  let partOnePercentage =
    (getQuestionAnalysisPartOne.score / getQuestionAnalysisPartOne.max) * 100;
  let partTwoPercentage =
    (getQuestionAnalysisPartTwo.score / getQuestionAnalysisPartTwo.max) * 100;
  let partThreePercentage =
    (getQuestionAnalysisPartThree.score / getQuestionAnalysisPartThree.max) *
    100;

    const [partTwoAudio, setPartTwoAudio] = useState<HTMLAudioElement | null>(
      null
    );
    const audioRefTwo = useRef<HTMLAudioElement>(null);
  
    const playpartTwoAudio = (audioUrl: string) => {
      // Stop the currently playing audio, if any
      if (partTwoAudio) {
        partTwoAudio.pause();
      }
  
      // Set the source and play the audio
      if (audioRefTwo.current) {
        audioRefTwo.current.src = audioUrl;
        audioRefTwo.current.play();
        setPartTwoAudio(audioRefTwo.current);
      }
    };

const startHandler = () => {
  dashboardStore.setProfilePageTabIndex(1);
  navigate("/profile");
}


  return (
    <div className="flex md:flex-row justify-between gap-1 flex-col">
      <div
        className="xl:w-[21.3125rem] md:w-1/3 w-full  h-3/4 border border-[#E3E5E9] rounded-[16px] mb-4"
        style={{
          display: `${
            completedTestType === "part1" || completedTestType === "full"
              ? ""
              : "none"
          } `,
        }}
      >
        {(completedTestType === "part1" || completedTestType === "full") && (
          <>
            <AIFeedbackPartHeader
              type="Part1"
              fraction={partOneAvgFraction}
              percentage={partOnePercentage}
              setAccordian={setAccordian}
              accordian={accordian}
            />
            {accordian.part1 && (
              <div
                className={`flex flex-col pt-[18px] pl-[11px] relative ${
                  accordian.part1 ? "border-t-2" : ""
                }`}
              >
                {getQuestionAnalysisPartOne.data &&
                getQuestionAnalysisPartOne.data.length > 0 ? (
                  getQuestionAnalysisPartOne.data.map((item: any) => {
                    return <PartBody item={item} key={item.question.text} />;
                  })
                ) : (
                  <span className="text-center text-[#737E93]">
                    {" "}
                    No Data Available
                  </span>
                )}
                {!userStore.isProUser && (
                  <div
                    className="flex flex-col absolute top-0 left-0 min-h-screen  right-0 rounded-b-[16px] bg-[#2D293566] w-full h-full justify-center items-center"
                    style={{ backdropFilter: "blur(5px)" }}
                  >
                    <div>
                      <img src={LockedIcon} alt="lock" />
                    </div>
                    <div className="text-[#FFFF] text-center">
                      {" "}
                      <p>
                        Get a plan to check your AI feedback. It shows
                        pronunciation, fluency and relevance of each sentence
                        you speak
                      </p>
                    </div>
                    <button
                      className="mx-auto bg-white rounded-full px-4 py-2 font-medium text-[#FF495C] cursor-pointer mt-5"
                      onClick={startHandler}
                    >
                      CHECKOUT OUR PLANS
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div
        className="xl:w-[21.3125rem] md:w-1/3 w-full  h-3/4 border border-[#E3E5E9] rounded-[16px] mb-4"
        style={{
          display: `${
            completedTestType === "part2" || completedTestType === "full"
              ? ""
              : "none"
          } `,
        }}
      >
        {(completedTestType === "part2" || completedTestType === "full") && (
          <>
            <AIFeedbackPartHeader
              type="Part2"
              fraction={partTwoAvgFraction}
              percentage={partTwoPercentage}
              setAccordian={setAccordian}
              accordian={accordian}
            />

            {accordian.part2 && (
              <div
                className={`flex flex-col pt-[18px] pl-[11px] relative ${
                  accordian.part2 ? "border-t-2" : ""
                }`}
              >
                {getQuestionAnalysisPartTwo.data &&
                getQuestionAnalysisPartTwo.data.length > 0 ? (
                  <>
                    <div className="flex flex-row justify-start ">
                      <img src={Icon} alt="examinerImg" />
                      <div className="border border-[#737E93] rounded-[16px] w-3/5 p-[8px] text-[#737E93] mx-[8px]">
                        {getQuestionAnalysisPartTwo.data.length > 0
                          ? getQuestionAnalysisPartTwo.data[0].answer.answer
                          : ""}
                      </div>
                      <button
                        className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[20px]"
                        onClick={() =>
                          playpartTwoAudio(
                            getQuestionAnalysisPartTwo.data[0].answer
                              .answer_audio
                          )
                        }
                      >
                        <img src={PlayIcon} alt="playImg" />
                        <audio ref={audioRefTwo}>
                          <track
                            kind="captions"
                            src=""
                            srcLang="en"
                            label="No captions available"
                          />
                        </audio>
                      </button>
                    </div>
                    {/* <div className="flex justify-end pt-[16px] pb-[8px] pr-[16px]">
                    <div className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[16px]">
                      <img src={PlayIcon} alt="playImg" />
                    </div>
                    <div className="border border-[#737E93] rounded-[16px] w-3/5 p-[8px] text-[#737E93] ml-[8px]">
                      {getQuestionAnalysisPartTwo.data.length > 0
                        ? getQuestionAnalysisPartTwo.data[0].answer.answer
                        : ""}
                    </div>
                  </div> */}
                      {!(getQuestionAnalysisPartTwo?.data[0]?.answer?.is_relevant_answer) && (
                        <div className="text-red-600 text-xs font-medium flex ml-8">
                          This Answer is Irrelevant
                        </div>
                      )}
                    <div className="flex justify-end pr-[16px] pb-[16px] text-[#737E93] text-[12px] ">
                      Pronunciation:
                      {getQuestionAnalysisPartTwo.data.length > 0
                        ? Math.round(
                            (getQuestionAnalysisPartTwo.data[0].answer
                              .pronunciation_score /
                              9) *
                              100
                          )
                        : 0}
                      % Fluency:
                      {getQuestionAnalysisPartTwo.data.length > 0
                        ? Math.round(
                            (getQuestionAnalysisPartTwo.data[0].answer
                              .fluency_score /
                              9) *
                              100
                          )
                        : 0}
                      % Grammar:{" "}
                      {getQuestionAnalysisPartTwo.data.length > 0
                        ? Math.round(
                            (getQuestionAnalysisPartTwo.data[0].answer
                              .grammar_score /
                              9) *
                              100
                          )
                        : 0}
                      % Vocabulary:
                      {getQuestionAnalysisPartTwo.data.length > 0
                        ? Math.round(
                            (getQuestionAnalysisPartTwo.data[0].answer
                              .vocabulary_score /
                              9) *
                              100
                          )
                        : 0}
                      %
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center pt-3 ">
                    <span className="text-center text-[#737E93]">
                      {" "}
                      No Data Available
                    </span>
                  </div>
                )}

                {!userStore.isProUser && (
                  <div
                    className="flex flex-col absolute top-0 min-h-screen right-0 rounded-b-[16px] bg-[#2D293566] w-full lg:h-full justify-center items-center"
                    style={{ backdropFilter: "blur(5px)" }}
                  >
                    <div>
                      <img src={LockedIcon} alt="lock" />
                    </div>
                    <div className="text-[#FFFF] text-center">
                      {" "}
                      <p>
                        Get a plan to check your AI feedback. It shows
                        pronunciation, fluency and relevance of each sentence
                        you speak
                      </p>
                    </div>
                    <button
                      className="mx-auto bg-white rounded-full px-4 py-2 font-medium text-[#FF495C] cursor-pointer mt-5"
                      onClick={startHandler}
                    >
                      CHECKOUT OUR PLANS
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <div
        className="xl:w-[21.3125rem] md:w-1/3  w-full  h-3/4 border border-[#E3E5E9] rounded-[16px] "
        style={{
          display: `${
            completedTestType === "part3" || completedTestType === "full"
              ? ""
              : "none"
          } `,
        }}
      >
        {(completedTestType === "part3" || completedTestType === "full") && (
          <>
            <AIFeedbackPartHeader
              type="Part3"
              fraction={partThreeAvgFraction}
              percentage={partThreePercentage}
              setAccordian={setAccordian}
              accordian={accordian}
            />
            {accordian.part3 && (
              <div
                className={`flex flex-col pt-[18px] pl-[11px] relative ${
                  accordian.part3 ? "border-t-2" : ""
                }`}
              >
                {getQuestionAnalysisPartThree.data &&
                getQuestionAnalysisPartThree.data.length > 0 ? (
                  getQuestionAnalysisPartThree.data.map(
                    (item: any, index: any) => {
                      return <PartBody item={item} key={item} />;
                    }
                  )
                ) : (
                  <span className="text-center text-[#737E93]  ">
                    {" "}
                    No Data Available
                  </span>
                )}
                {!userStore.isProUser && (
                  <div
                    className="flex flex-col absolute top-0 left-0 min-h-screen  right-0 rounded-b-[16px] bg-[#2D293566] w-full h-full justify-center items-center "
                    style={{ backdropFilter: "blur(5px)" }}
                  >
                    <div>
                      <img src={LockedIcon} alt="lock" />
                    </div>
                    <div className="text-[#FFFF] text-center">
                      {" "}
                      <p>
                        Get a plan to check your AI feedback. It shows
                        pronunciation, fluency and relevance of each sentence
                        you speak
                      </p>
                    </div>
                    <button
                      className="mx-auto bg-white rounded-full px-4 py-2 font-medium text-[#FF495C] cursor-pointer mt-5"
                      onClick={startHandler}
                    >
                      CHECKOUT OUR PLANS
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(AiFeedback);
